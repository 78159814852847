// 404 page
<template>
  <div class="not-found">
    <h1 class="header-404">
      404
    </h1>
    <p class="text-404">
      This page is unavailable or does not exist.
      立ち返る。
    </p>
  </div>
</template>

<script>
export default {
  name: '404',
}
</script>

<style lang="stylus" scoped>
@import '../css/globals.styl';

.not-found {
  color: $blue;
  text-align: center;
}

.header-404 {
  font-size: 5rem;
  margin: 3rem 0 3rem;
  text-shadow: 2px 2px 0px $dark-grey;
}

.text-404 {
  font-size: 1.25rem;
  font-weight: bold;
  text-shadow: 1px 1px 0px $dark-grey;
}
</style>